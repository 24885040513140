import React, { useMemo } from "react";
import dayjs from "lib/dayjs";
import { styles, topLeftBlankCss } from "./styles";
import { ResourceHeader } from "./ResourceHeader";
import { Cell } from "./Cell";
import { css } from "@emotion/react";
import { gql, useQuery } from "@apollo/client";
import { useAuth } from "lib/firebase/hooks";
import { useTranslation } from "react-i18next";

const cellAvailableTextCss = css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
});
type TimeTableProps = {
    date: dayjs.Dayjs;
    scheduleRange: { start: dayjs.Dayjs; end: dayjs.Dayjs };
    schedule: ServiceUnavailabilities[];
};
const DailyTimeTable = React.memo<TimeTableProps>(
    ({ schedule, scheduleRange }): JSX.Element => {
        const { t } = useTranslation("components/AvailabilitySchedule");
        const dates: dayjs.Dayjs[] = [];
        let i = scheduleRange.start;
        while (i.isSameOrBefore(scheduleRange.end)) {
            dates.push(i);
            i = i.add(1, "d");
        }

        return (
            <div
                style={{
                    gridTemplateColumns: `60px repeat(${schedule.length}, 150px)`,
                    gridTemplateRows: `minmax(32px, min-content) repeat(${dates.length}, 50px)`,
                }}
                css={styles.timetableWrapper}
            >
                <div key="topleftblank" css={topLeftBlankCss} />
                {schedule.map((s) => (
                    <ResourceHeader
                        key={s.uid + "head"}
                        css={styles.stickyTop}
                        name={s.name}
                    />
                ))}
                {dates.map((d, idx, arr) => (
                    <>
                        <DateHeader key={d.unix()} date={d} />
                        {schedule.map((s) => {
                            const noOfResources = s.resources.length;
                            const noOfUnavailableResources = s.resources.filter(
                                (r) =>
                                    s.unavailabilities.some(
                                        (st) =>
                                            r.uid === st.resourceId &&
                                            isBetween(st, d, s.startTime),
                                    ),
                            ).length;
                            const availableLength =
                                noOfResources - noOfUnavailableResources;
                            return (
                                <Cell
                                    key={s.uid + d.toISOString()}
                                    vertical
                                    roundStart={
                                        !idx ||
                                        !s.resources.every((r) =>
                                            s.unavailabilities.some(
                                                (st) =>
                                                    r.uid === st.resourceId &&
                                                    isBetween(
                                                        st,
                                                        arr[
                                                            idx - 1
                                                        ] as dayjs.Dayjs,
                                                        s.startTime,
                                                    ),
                                            ),
                                        )
                                    }
                                    roundEnd={
                                        !arr[idx + 1] ||
                                        !s.resources.every((r) =>
                                            s.unavailabilities.some(
                                                (st) =>
                                                    r.uid === st.resourceId &&
                                                    isBetween(
                                                        st,
                                                        arr[
                                                            idx + 1
                                                        ] as dayjs.Dayjs,
                                                        s.startTime,
                                                    ),
                                            ),
                                        )
                                    }
                                    isAvailable={!!availableLength}
                                >
                                    {!!availableLength && (
                                        <div css={cellAvailableTextCss}>
                                            {availableLength}{" "}
                                            {t("available", {
                                                defaultValue: "Available",
                                            })}
                                        </div>
                                    )}
                                </Cell>
                            );
                        })}
                    </>
                ))}
            </div>
        );
    },
    (prev, next) =>
        prev.schedule === next.schedule &&
        prev.scheduleRange.start.unix() === next.scheduleRange.start.unix() &&
        prev.scheduleRange.end.unix() === next.scheduleRange.end.unix(),
);
DailyTimeTable.displayName = "DailyTimeTable";

const dateHeaderCss = css([
    styles.solidBorder,
    styles.borderLeftNone,
    styles.stickyLeft,
    styles.bgGrey,
    {
        textAlign: "center",
        padding: 4,
    },
]);
const DateHeader = React.memo<{ date: dayjs.Dayjs }>(({ date }) => (
    <div css={dateHeaderCss}>{date.format("D MMM")}</div>
));
DateHeader.displayName = "DateHeader";

export { DailyTimeTable };

function isBetween(
    dr: { start: dayjs.Dayjs; end: dayjs.Dayjs },
    d: dayjs.Dayjs,
    st: string,
): boolean {
    const t = dayjs(st, "HH:mm:ss");
    d = d.hour(t.hour()).minute(t.minute());
    return dr.start.isSameOrBefore(d) && dr.end.isSameOrAfter(d);
}

type UnavailableSlotsServiceData = {
    unavailableResourceSlotsBetween: {
        resourceId: string;
        startDt: string;
        endDt: string;
    }[];
    onlineServices: {
        uid: string;
        name: string;
        startTime: string;
        resources: {
            uid: string;
            archived?: string;
            resourceId: string;
        }[];
    }[];
    serviceTags: { serviceId: string }[];
    myMemberServiceIds?: string[];
};
const unavailableSlotsServiceQuery = gql`
    query unavailableSlotsService(
        $categoryId: ID!
        $dtRange: DateRangeFilter!
        $tenantId: ID!
        $isLoggedIn: Boolean!
    ) {
        unavailableResourceSlotsBetween(
            categoryId: $categoryId
            dtRange: $dtRange
            tenantId: $tenantId
        ) {
            resourceId
            startDt
            endDt
        }
        onlineServices(
            tenantId: $tenantId
            filter: { byCategoryId: $categoryId }
        ) {
            uid
            name
            startTime
            resources {
                uid
                archived
                resourceId
            }
        }
        serviceTags(tenantId: $tenantId) {
            serviceId
        }
        myMemberServiceIds(tenantId: $tenantId) @include(if: $isLoggedIn)
    }
`;
type ServiceUnavailabilities = {
    uid: string;
    name: string;
    startTime: string;
    resources: { uid: string }[];
    unavailabilities: {
        resourceId: string;
        start: dayjs.Dayjs;
        end: dayjs.Dayjs;
    }[];
};
export const useServiceUnavailabilities = (
    tenantId: string | undefined,
    categoryId: string | undefined,
    dateRange: { start: dayjs.Dayjs; end: dayjs.Dayjs } | undefined,
): ServiceUnavailabilities[] | undefined => {
    const { user, isInitialised } = useAuth();
    const { data } = useQuery<UnavailableSlotsServiceData>(
        unavailableSlotsServiceQuery,
        {
            skip: !tenantId || !categoryId || !dateRange || !isInitialised,
            variables: {
                tenantId,
                categoryId,
                dtRange: {
                    startDt: dateRange?.start.toISOString(),
                    endDt: dateRange?.end.toISOString(),
                },
                isLoggedIn: !!user,
            },
            fetchPolicy: "network-only",
        },
    );
    return useMemo(
        () =>
            data?.onlineServices
                .filter(
                    (s) =>
                        !data.serviceTags.some((t) => t.serviceId === s.uid) ||
                        data.myMemberServiceIds?.includes(s.uid),
                )
                .map((s) => ({
                    ...s,
                    resources: s.resources
                        .filter((r) => !r.archived)
                        .map(({ resourceId }) => ({ uid: resourceId })),
                    unavailabilities: data.unavailableResourceSlotsBetween
                        .filter((u) => {
                            const resources = s.resources.filter(
                                (r) => !r.archived,
                            );
                            return resources.some(
                                // Keep only unavailabilities that relate to this service
                                (r) => r.resourceId === u.resourceId,
                            );
                        })
                        .map((u) => ({
                            resourceId: u.resourceId,
                            start: dayjs(u.startDt),
                            end: dayjs(u.endDt),
                        })),
                })),
        [data],
    );
};
