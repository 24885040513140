import { css } from "@emotion/react";

export const styles = {
    solidBorder: css({ border: "0.8px solid #E2E2E2" }),
    borderTopNone: css({ borderTop: "none" }),
    borderLeftNone: css({ borderLeft: "none" }),
    borderRightNone: css({ borderRight: "none" }),
    bgWhite: css({ backgroundColor: "white" }),
    bgGrey: css({ backgroundColor: "#F5F5F5" }),
    bgBlue: css({ backgroundColor: "#EBEEFD" }),
    stickyTop: css({ position: "sticky", top: 0 }),
    stickyLeft: css({ position: "sticky", left: 0 }),
    timetableWrapper: css({
        flex: "1 0 0",
        backgroundColor: "white",
        display: "grid",
        scrollSnapType: "both mandatory",
        scrollPadding: "32px 0 0 100px",
        overflow: "auto",
        maxWidth: "calc(100% - 1px)",
        marginBottom: 8,
    }),
};

export const topLeftBlankCss = css([
    styles.solidBorder,
    styles.stickyTop,
    styles.stickyLeft,
    styles.bgGrey,
    { zIndex: 1 },
]);
