import { css } from "@emotion/react";
import React from "react";
import { styles } from "./styles";

const style = css([
    styles.solidBorder,
    styles.borderTopNone,
    styles.bgGrey,
    {
        padding: 4,
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        textAlign: "center",
        overflow: "hidden",
        lineClamp: 2,
        WebkitLineClamp: 2,
    },
]);

const ResourceHeader = React.memo<{ name: string; className?: string }>(
    ({ name, className }) => (
        <div css={style} className={className}>
            {name}
        </div>
    ),
);
ResourceHeader.displayName = "ResourceHeader";
export { ResourceHeader };
