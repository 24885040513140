import { useMemo } from "react";

export const useOrgMetadata = (
    metadata: string | null | undefined,
): OrgMetadata | undefined =>
    useMemo(() => {
        if (!metadata) return undefined;
        const m: ParsedMetadata = JSON.parse(metadata);
        const images =
            m.centreImages ??
            m.centreImageURLs?.map((url) => ({ url, tags: [] })) ??
            [];
        let announcement;
        if (m.announcement && m.announcementCreated)
            announcement = {
                text: m.announcement,
                date: new Date(m.announcementCreated),
            };
        return {
            images,
            announcement,
            amenities: m.amenities ?? [],
            centrePolicy: m.centrePolicy ?? "",
            cityAndState: m.cityAndState ?? "",
            address: m.address ?? "",
            directions: m.directions ?? "",
            openingHours: m.operatingHours ?? "",
            price: m.price ?? "",
            centreLayoutImgs: m.centreLayoutImgs ?? [],
            directionImages: m.centreDirections ?? [],
            phoneNumber: m.phoneNumber ?? "",
            bookingRemarks: m.remarks ?? "",
            cover: m.centreImageURLs?.[0] ?? "",
            showRemarks: m.showRemarks === "true",
            socials: m.socialMediaLinks ?? {},
        };
    }, [metadata]);

export type OrgMetadata = {
    images: { url: string; tags: string[] }[];
    announcement?: { text: string; date: Date };
    amenities: string[];
    centrePolicy: string;
    cityAndState: string;
    address: string;
    directions: string;
    openingHours: string;
    price: string;
    centreLayoutImgs: { url: string; tags: string[] }[];
    directionImages: string[];
    phoneNumber: string;
    bookingRemarks: string;
    cover: string;
    showRemarks: boolean;
    socials: {
        facebook?: string;
        twitter?: string;
        instagram?: string;
        youtube?: string;
        website?: string;
        tiktok?: string;
    };
};
type ParsedMetadata = {
    centreImages?: { url: string; tags: string[] }[];
    centreImageURLs?: string[];
    announcement?: string;
    announcementCreated?: string;
    amenities?: string[];
    centrePolicy?: string;
    cityAndState?: string;
    address?: string;
    directions?: string;
    operatingHours?: string;
    price?: string;
    centreLayoutImgs?: { url: string; tags: string[] }[];
    centreDirections?: string[];
    phoneNumber?: string;
    remarks?: string;
    showRemarks?: string;
    socialMediaLinks?: {
        facebook?: string;
        instagram?: string;
        twitter?: string;
        youtube?: string;
        website?: string;
        tiktok?: string;
    };
};
